import React from 'react'
import AboutInHomePage from '../about/AboutInHomePage'

const AboutPage = () => {
  return (
    <>
       <AboutInHomePage />
    </>
  )
}

export default AboutPage